body {
  margin: 0;
  font-family: system-ui, -apple-system, "Segoe UI", Roboto,
          "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
          "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol",
          "Noto Color Emoji";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#name {
  font-size: 5rem;
  font-weight: 300;
  margin-left: -0.3rem;
}

#title {
  font-size: 3rem;
  font-weight: 300;
  margin-left: -0.2rem;
}
